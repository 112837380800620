import { Flex, StatGroup, Stat, Divider, Spinner, Text, HStack, Box, VStack, StatArrow, useMediaQuery } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { AmountUtil } from "src/data/AmountUtil";
import { excangeRateService } from "src/service/ExchangeRateService";
import CountryFlag from "./CountryFlag";

export interface ExchangeRatesProps {
    base: string;
    all: Array<string>;
}

const ExchangeRates = (props: ExchangeRatesProps) => {

    const [data, setData] = useState<Map<string, number>>(new Map());
    const [history, setHistory] = useState<Map<String, Map<String, number>>>(new Map());
    const [isLoading, setIsLoading] = useState(true);
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
    const rateWidth = isSmallScreen ? 250 : 350;
    console.log(`small screen: ${isSmallScreen}`);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                // execute seqentially, as the first call will fill the cache on the server side
                const historyResponse = props.all.reduce((p, counter) => p.then(() =>
                    excangeRateService.listHistory(props.base, counter)
                        .then(res => setHistory(v => v.set(counter, new Map(Object.entries(res)))))
                ), Promise.resolve());
                const [ratesResponse, _] = await Promise.all([
                    excangeRateService.listRates(props.base),
                    historyResponse
                ]);
                setData(new Map(Object.entries(ratesResponse.rates)));
            } catch (error) {
                console.error('Error fetching exchange rates:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [props.base]);

    return (
        <Flex pt='1rem' justify={'space-between'} direction={{ base: 'column', sm: 'row' }}>
            <StatGroup shadow={'md'} border={'1px solid lightgray'} rounded={'md'} mx='1rem' textAlign='right'>
                {props.all.map((counter, ix) => {
                    const rate = data.get(counter) ?? 0;
                    const aHistory: Map<string, number> = history.get(counter) ?? new Map();
                    const series = Array.from(aHistory).map(([name, value]: [string, number]) => {
                        let c = {
                            x: new Date(name).getTime(),
                            y: value
                        };
                        return c;
                    });
                    // calculate if increasing, if the last point is bigger than the previous from the series
                    const decreasing = series.length > 1 && series[series.length - 1].y < series[series.length - 2].y;
                    return (
                        <Box px='0.1rem' key={ix} borderLeft={ix > 0 ? '1px solid lightgray' : ''}>
                            <HStack justify={'space-between'} alignItems={"flex-end"} px='.5rem'>
                                <Flex>
                                    <VStack spacing='0' pt='3px'>
                                        {/* align to middle */}
                                        <CountryFlag code={props.base.substring(0, 2)} />
                                        <CountryFlag code={counter.substring(0, 2)} />
                                    </VStack>
                                    <Text pl='.4rem' fontWeight={'bold'} fontSize={'1.5rem'} color='blue.700'>{props.base}/{counter}</Text>
                                </Flex>
                                {isLoading ?
                                    (<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='md' />)
                                    :
                                    (<Flex>
                                        <Stat>
                                            <StatArrow type={decreasing ? 'decrease' : 'increase'} />
                                        </Stat>
                                        <Text fontWeight={'extrabold'} fontSize={'1.5rem'} color={rate > 0 ? 'black' : 'red'}>{AmountUtil.format(rate)}</Text>
                                    </Flex>)
                                }

                            </HStack>
                            <Divider orientation='horizontal' p='0px' />
                            {series && (<HistoryChart data={series} width={rateWidth}/>)}
                        </Box>
                    )
                })}
            </StatGroup>
        </Flex>
    );
}

interface HistoryChartProps {
    data: Array<{
        x: number;
        y: number;
    }>;
    width: number;
}

const HistoryChart = ({ data, width }: HistoryChartProps) => {
    const height = 100;
    const options: ApexOptions = {
        chart: {
            id: "area-datetime",
            height: height,
            defaultLocale: 'en',
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false,
            },
            dropShadow: {
                enabled: true,
                top: .5,
                left: 0,
                blur: 1,
                opacity: 0.5
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'datetime',
            tickAmount: 6,
        },
        yaxis: {
            labels: {
                formatter: function (val: any) {
                    return AmountUtil.format(val);
                },
            }
        },
    };
    const series: ApexAxisChartSeries = [
        {
            name: 'Kurs',
            data: data
        }
    ]
    return (
        <div><ReactApexChart options={options} series={series} type="area" height={height} width={width} /></div>
    )
}

export default ExchangeRates