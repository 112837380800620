import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Flex, useColorModeValue, Stack, Heading, Box, FormControl, FormLabel, InputGroup, InputLeftElement, Input, Button, InputRightElement, FormErrorMessage } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { BiEnvelope, BiUser } from "react-icons/bi";
import PasswordStrengthBar from "react-password-strength-bar";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "src/components/ErrorMessage";
import { userService } from "src/service/UserService";

interface NewUser {
    email: string;
    password: string;
    password2: string,
    firstName: string;
    lastName: string;
}

const UsersAdd = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting }, watch } = useForm<NewUser>({
        defaultValues: {
            email: null,
            password: '',
            password2: '',
            firstName: null,
            lastName: null,
        }
    });
    const newPassword = watch('password', '');
    const newPassword2 = watch('password2', '');
    const [serverError, setServerError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const readOnly = false;
    const navigate = useNavigate();
    const onSubmitInternal = useCallback(
        (p: NewUser): Promise<void> => {
            return userService.add(p.email, p.password, p.firstName, p.lastName).then(() =>
                navigate('/admin/users/')).catch(err => setServerError('Fehler'));
        }, [navigate]
    );

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.25', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} minW={'lg'} maxW={'2xl'} py={12} px={6}>
                <form onSubmit={handleSubmit(onSubmitInternal)}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>Neuer Benutzer</Heading>
                    </Stack>
                    <Box
                        rounded={'lg'}
                        bg={useColorModeValue('gray.25', 'gray.700')}
                        boxShadow={'lg'}
                        p={8}>
                        <Stack spacing={4}>
                            {serverError && <ErrorMessage message={serverError} />}

                            <Box>
                                <FormControl id="email" isRequired isReadOnly={readOnly} isInvalid={!!errors['email']}>
                                    <FormLabel>E-Mail</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiEnvelope color='blue' />} />
                                        <Input autoFocus={true} type="text" {...register('email')}/>
                                    </InputGroup>
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControl id="password" isRequired>
                                    <FormLabel>Passwort</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<LockIcon color='yellow.500' />} />
                                        <Input type={showPassword ? 'text' : 'password'} {...register('password')} />
                                        <InputRightElement h={'full'}>
                                            <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <PasswordStrengthBar password={newPassword} shortScoreWord='zu kurz' scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']} />
                                </FormControl>

                                <FormControl id="confirmPassword" isRequired isInvalid={newPassword !== newPassword2}>
                                    <FormLabel>Passwort wiederholen</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<LockIcon color='yellow.500' />} />
                                        <Input type={showPassword ? 'text' : 'password'} {...register('password2', {
                                            validate: (value) => value === newPassword || 'Passwörter stimmen nicht überein'
                                        })} />
                                        <InputRightElement h={'full'}>
                                            <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>{errors.password2?.message}</FormErrorMessage>
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControl id="firstName" isRequired isReadOnly={readOnly}>
                                    <FormLabel>Vorname</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiUser color='blue' />} />
                                        <Input type="text" {...register('firstName')} />
                                    </InputGroup>
                                </FormControl>
                            </Box>

                            <Box>
                                <FormControl id="lastName" isRequired isReadOnly={readOnly}>
                                    <FormLabel>Nachname</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' children={<BiUser color='blue' />} />
                                        <Input type="text" {...register('lastName')} />
                                    </InputGroup>
                                </FormControl>
                            </Box>

                            <Stack spacing={10} pt={2}>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'center'}
                                    justify={'space-between'}
                                    spacing={5}>
                                    <Button isDisabled={isSubmitting}
                                        onClick={() => navigate('/admin/users/')}
                                        bg={'red.400'} color={'white'} _hover={{ bg: 'red.500' }}>
                                        Schliessen
                                    </Button>
                                    {!readOnly && (
                                        <Button isLoading={isSubmitting} type='submit' bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}>
                                            Speichern
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>

                        </Stack>
                    </Box>
                </form>
            </Stack>
        </Flex>
    )
}

export default UsersAdd